import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { setContext, resetContext } from '../../actions'
import { DownOutlined } from '@ant-design/icons'
import { Menu, Dropdown, Button } from 'antd'
import '../../scss/elements/dropdown-user.scss'
const SubMenu = Menu.SubMenu

class DropDownMenu extends Component {
	constructor(props) {
		super(props)
		this.state = {
			menu: (
				<Menu>
					<Menu.Item>Loading Partners...</Menu.Item>
				</Menu>
			)
		}
	}

	renderSelectGroup = (hash, name) => {
		const { setContext, user } = this.props

		let roles = [
			'Operations',
			'Developer',
			'Corporate',
			'Vendor Outreach',
			'QA-User',
			'Analyst'
		]

		return roles.indexOf(user.role_name) !== -1 && hash !== 'Others' ? (
			<Menu.Item
				key={`${hash}_grp`}
				onClick={() => setContext('Partner Group', hash, name)}>
				Select Group
			</Menu.Item>
		) : null
	}

	renderMenu = () => {
		const { partners, setContext, resetContext, user } = this.props
		let partnerGroups = _.groupBy(
			_.sortBy(partners, 'partner_group_name'),
			p => p.partner_group_hash || 'Others'
		)
		return (
			<Menu>
				{["Operations", "Analyst", "Developer"].includes(user.role_name) && (
					<Menu.Item onClick={() => resetContext()}>Clear Context</Menu.Item>
				)}
				{_.map(partnerGroups, (g, hash) => {
					let groupName = hash === 'Others' ? 'Others' : g[0].partner_group_name
					return (
						<SubMenu key={hash} title={groupName}>
							{this.renderSelectGroup(hash, groupName)}
							{_.sortBy(g, 'name').map(p => {
								return (
									<Menu.Item
										key={p.hashid}
										onClick={() => setContext('Partner', p.hashid, p.name)}>
										{p.name}
									</Menu.Item>
								)
							})}
						</SubMenu>
					)
				})}
			</Menu>
		)
	}

	render() {
		const {
			partnerGroups,
			className,
			partnerContext: { type, name, hash }
		} = this.props

		const menuBody =
			partnerGroups === null ? this.state.menu : this.renderMenu()
		return (
			<Dropdown overlay={menuBody} trigger={['click']}>
				<Button
					id="partner-context"
					ghost
					className={`${hash === null ? '' : ''}${className}`}>
					{hash === null ? 'Select A Store Group' : `${type} - ${name}`}{' '}
					<DownOutlined />
				</Button>
			</Dropdown>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		partnerGroups: state.partnerGroups.group,
		partners: state.partners.partners,
		partnerContext: state.partnerContext,
		user: state.user.userData
	}
}

export default connect(mapStateToProps, { setContext, resetContext })(
	DropDownMenu
)
