import { SET_CONTEXT, RESET_CONTEXT } from './types'

import { resetStoredTableStates } from './storedTableState'
import { updateLocalPartnerContext } from '../api/storage.service'

export const setContext = (type, hash, name) => async dispatch => {
	dispatch({ type: SET_CONTEXT, payload: { type, hash, name } })
	//reset any stored table states to initial defaults
	dispatch(resetStoredTableStates())
	updateLocalPartnerContext({ type, hash, name })
}

export const resetContext = () => async dispatch => {
	dispatch({
		type: RESET_CONTEXT,
		payload: {
			type: null,
			hash: null,
			name: null
		}
	})
	//reset any stored table states to initial defaults
	dispatch(resetStoredTableStates())
	updateLocalPartnerContext(null, true)
}
